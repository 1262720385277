import { required } from 'vuelidate/lib/validators';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    props: ['popupType', 'selectedItem'],
    components: {
      loader: () => import('../../../loader/loader-min.vue'),
    },
    data() {
        return {
            loadAsync: false,
            form: {
                title: '',
                description: '',
                np_title: 'Нова Пошта',
                np_logo: 'http://textilelux.lp-crm.biz/mods/nova_poshta/logo.png',
                np_module: 'nova_poshta',
                np_description: 'Курьерская служба №1 в Украине',
                np_status: 1,
                np_phone: '',
                np_firstname: '',
                np_lastname: '',
                np_middlename: '',
                np_api_key: '',
                np_area:null,
                np_city:null,
                np_warehouse:null,
                np_counterparty_sender:null,
                np_counterparty_address:null,
            },
            list: {
            },
            npAreasList:[],
            npAreasListId:null,
            npCityList:[],
            npCityListId:null,
            npCityWarehouses:[],
            npCityWarehousesId:null,
            contragent: [
                {
                    id:'none',
                    title:'Нету'
                }
            ],
            address:[
                {
                    id:'none',
                    title:'Нету'
                }
            ]
        }
    },

    validations: {
        form: {
            np_title: {
                required
            }
        }
    },
    computed: {
        ...mapGetters({
            options:'config/options',
            npWarehouses: 'plugins/npWarehouses',
            warehouse: 'warehouse/warehouse',
            npAreas: 'plugins/npAreas',
            npCity: 'plugins/npCity',
            counterpartySender: 'plugins/counterpartySender',
            counterpartyAddress: 'plugins/counterpartyAddress',
        }),
    },
    watch:{
        npAreas(e){
            this.npAreasList = [];
            this.npCityList = [];
            this.list.np_area = null;
            this.list.np_city = null;
            this.npCityWarehouses = [];
            this.list.np_warehouse = null;
            let key;
            for(key in e.models){
                this.npAreasList.push(
                    {
                        id:key,
                        title:e.models[key],
                    }
                )
            }
            this.checkOption(this.options);
        },
        npCity(e){
            this.npCityList = [];
            this.list.np_city = null;
            this.npCityWarehouses = [];
            this.list.np_warehouse = null;
            let key;
            for(key in e.models){
                this.npCityList.push(
                    {
                        id:key,
                        title:e.models[key],
                    }
                )
            }
        },
        npWarehouses(e){
            this.npCityWarehouses = [];
            this.list.np_warehouse = null;
            let key;
            for(key in e.models){
                this.npCityWarehouses.push(
                    {
                        id:key,
                        title:e.models[key],
                    }
                )
            }
        },
        options(e){
            this.checkOption(e);
        },
        counterpartySender(e){
            this.contragent = [];
            let key;
            for(key in e.models){
                this.contragent.push({
                    id: key,
                    title: e.models[key],
                })
            }
            this.getCounterpartyAddress({counterparty_id:this.form.np_counterparty_sender, np_api_key: this.form.np_api_key});
        },
        counterpartyAddress(e){
            this. address = [];
            let key; 
            if(e.models.address){
                for(key in e.models.address){
                    this.address.push({
                        id: key,
                        title: e.models[key],
                    })
                } 
            }else{
                this.form.np_counterparty_address = ''
            }
            if(e.models.contact){ 
                const contcatList = e.models.contact;
                this.form.np_firstname = contcatList.first_name ? contcatList.first_name : ''
                this.form.np_lastname = contcatList.last_name ? contcatList.last_name : ''
                this.form.np_middlename = contcatList.middle_name ? contcatList.middle_name : ''
                this.form.np_phone = contcatList.phone ? contcatList.phone : ''
            }
        }
    },
    created() {
        this.getNpAreas();
        this.checkOption(this.option);
    },
    methods: {
        npApiDataCync(){
            this.loadAsync = true
            this.getCounterpartySender({np_api_key: this.form.np_api_key}).then(res => {
                Object.keys(res.models).map(item => {
                    this.form.np_counterparty_sender = item;
                })
                this.getCounterpartyAddress({counterparty_id:this.form.np_counterparty_sender, np_api_key: this.form.np_api_key});
                this.loadAsync = false 
            }).catch(e => {
                console.log('e - ', e.response.data.errors);
                 Object.keys(e.response.data.errors).map(item =>{
                    this.$toasted.error(e.response.data.errors[item],{
                        duration : 3000,
                        position: 'top-center',
                    });
                 })
                this.loadAsync = false
            });
        },
        checkOption(res){
            console.log(res);
            if(res){
                this.form = res.models;
                this.selectedArea();
                this.selectedCity()
            }else{
                this.form = this.list
            }

        this.getCounterpartySender({np_api_key: this.form.np_api_key});
        },
        selectedArea(){
            this.getNpCity({'area_id': this.form.np_area})
        },
        selectedCity(){
            this.getNpWarehouses({'city_id': this.form.np_city})
        },
        closePopup() {
            this.$emit('closePopup')
        },
        sendConfirm() {
            if(this.warehouse){
                this.$emit('changeOptions', this.form)
            }else{
                this.$emit('sendOptions', this.form)
            }
        },
        getSenderAddress(){
            this.getCounterpartyAddress({counterparty_id:this.form.np_counterparty_sender});
        },
        ...mapActions({
            getNpAreas:'plugins/getNpAreas',
            getNpCity:'plugins/getNpCity',
            getNpWarehouses:'plugins/getNpWarehouses',
            getCounterpartySender:'plugins/getCounterpartySender',
            getCounterpartyAddress:'plugins/getCounterpartyAddress',
        }),
        ...mapMutations({
            changeShowWarehouse: 'warehouse/changeShowWarehouse'
        })
    },
    destroyed() {
        this.changeShowWarehouse(null);
    }
}